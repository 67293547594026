<template>
	<v-container fluid class="fill-height">
		<v-row justify="center">
			<v-col cols="12" lg="7">
				<v-stepper v-model="stepNr">
					<v-stepper-header class="elevation-0">
						<v-stepper-step :complete="stepNr > 1" step="1">
							Check out
						</v-stepper-step>
						<v-divider />
						<v-stepper-step :complete="stepNr > 2" step="2">
							Delivery
						</v-stepper-step>
						<v-divider />
						<v-stepper-step :complete="stepNr > 3" step="3">
							Invoice
						</v-stepper-step>
						<v-divider />
						<v-stepper-step :complete="stepNr > 4" step="4">
							Convert
						</v-stepper-step>
						<v-divider />
						<v-stepper-step step="5"> Done </v-stepper-step>
					</v-stepper-header>

					<v-stepper-items
						style="
							min-height: 450px;
							max-height: 600px;
							overflow-y: auto;
						"
					>
						<!-- STEP 1 -->
						<v-stepper-content step="1">
							<CartItemTable />
						</v-stepper-content>
						<!-- STEP 2 -->
						<v-stepper-content step="2">
							<OrderDeliveryForm />
						</v-stepper-content>
						<!-- STEP 3 -->
						<v-stepper-content step="3">
							<OrderInvoiceForm />
						</v-stepper-content>
						<!-- STEP 4 -->
						<v-stepper-content step="4">
							<v-card flat :max-width="600" class="mx-auto">
								<v-card-title
									>Convert shoppingcart to order</v-card-title
								>
								<v-card-text>
									<v-row dense>
										<v-col cols="12">
											<v-select
												v-model="paymentCode"
												:items="paymentOptions"
												:disabled="
													webUserInfo[0]
														.hasDeferredPayment ===
													true
												"
												item-text="type"
												item-value="code"
												label="Payment"
												hide-details
												flat
												single-line
												outlined
											></v-select>
										</v-col>
										<v-col cols="12">
											<v-text-field
												v-model="reference"
												label="Customer reference"
												outlined
											></v-text-field>
										</v-col>
										<v-col cols="12">
											<v-textarea
												v-model="remark"
												label="Remark"
												outlined
											></v-textarea>
										</v-col>
									</v-row>
								</v-card-text>
								<v-card-actions>
									<v-btn
										block
										color="primary"
										@click.stop="convertCart"
										:loading="loading"
										>convert to order</v-btn
									>
								</v-card-actions>
							</v-card>
						</v-stepper-content>
						<!-- STEP 5 -->
						<v-stepper-content step="5">
							<v-banner
								:icon="errorMsg ? 'mdi-close' : 'mdi-check'"
								:icon-color="errorMsg ? 'red' : 'green'"
							>
								<span v-if="errorMsg">
									{{ this.errorMsg }}</span
								>
								<span v-else>
									{{ this.result }}
								</span>
								<template v-slot:actions>
									<v-btn text @click.stop="stepNr = 1">
										OK
									</v-btn>
								</template>
							</v-banner>
						</v-stepper-content>
					</v-stepper-items>

					<v-toolbar flat>
						<v-btn v-show="showPrev" plain @click.stop="prevStep">
							<v-icon>mdi-chevron-left</v-icon>Back
						</v-btn>
						<v-spacer />
						<v-btn v-show="showNext" plain @click.stop="nextStep">
							Next
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</v-toolbar>
				</v-stepper>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	import { mapGetters } from "vuex";
	import CartItemTable from "@/components/CartItemTable";
	import OrderDeliveryForm from "@/components/OrderDeliveryForm";
	import OrderInvoiceForm from "@/components/OrderInvoiceForm";

	export default {
		name: "ShoppingCart",
		components: {
			CartItemTable,
			OrderDeliveryForm,
			OrderInvoiceForm,
		},
		data() {
			return {
				stepNr: 1,
				loading: false,
				errorMsg: null,
				result: "",
				paymentOptions: [
					{ type: "visa", code: 40 },
					{ type: "american express", code: 41 },
					{ type: "mastercard", code: 42 },
					{ type: "maestro", code: 43 },
					{ type: "ideal", code: 44 },
					{ type: "paypall", code: 60 },
				],
			};
		},
		computed: {
			...mapGetters({
				cart: "shoppingCart/cart",
				cartItemCount: "shoppingCart/cartItemCount",
				environment: "session/environment",
				webUserInfo: "session/webUserInfo",
			}),
			paymentCode: {
				get() {
					return this.$store.state.shoppingCart.paymentCode;
				},
				set(code) {
					this.$store.dispatch("shoppingCart/setPaymentCode", code);
				},
			},
			remark: {
				get() {
					return this.$store.state.shoppingCart.remark;
				},
				set(text) {
					this.$store.dispatch("shoppingCart/setRemark", text);
				},
			},
			reference: {
				get() {
					return this.$store.state.shoppingCart.reference;
				},
				set(text) {
					this.$store.dispatch("shoppingCart/setReference", text);
				},
			},
			showPrev() {
				if (this.stepNr === 1) return false;
				return this.cartItemCount > 0; // prevent returning when cart is converted
			},
			showNext() {
				switch (this.stepNr) {
					case 1:
						return this.cartItemCount > 0;
					case 2:
						return true;
					case 3:
						return true;
					default:
						return false;
				}
			},
		},
		created() {
			this.$store.dispatch("shoppingCart/fetchShoppingCart").then(() => {
				this.$store.dispatch("account/fetchWebUserProfile");
			});
		},
		methods: {
			prevStep() {
				switch (this.stepNr) {
					case 3:
						if (this.cart.transportType === "COLLECT") {
							this.stepNr = 1;
							break;
						}
						this.stepNr = 2;
						break;
					default:
						this.stepNr -= 1;
				}
			},
			nextStep() {
				switch (this.stepNr) {
					case 1:
						if (this.cart.transportType === "COLLECT") {
							this.stepNr = 3;
							break;
						}
						this.stepNr = 2;
						break;
					default:
						this.stepNr += 1;
				}
			},
			convertCart() {
				if (this.environment === "LIVE") {
					this.result =
						"The procedure ends here because this is the live environment.";
					this.nextStep();
					return;
				}

				let that = this;
				this.loading = true;
				this.$store
					.dispatch("shoppingCart/convertShoppingCart")
					.then((response) => {
						this.result =
							"The shoppingCart has been converted to ordernr: " +
							response.orderId;
					})
					.catch((error) => {
						this.errorMsg = error;
					})
					.finally(() => {
						window.setTimeout(function () {
							that.nextStep();
							that.loading = false;
						}, 750);
					});
			},
		},
	};
</script>
