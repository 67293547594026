import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"7"}},[_c(VStepper,{model:{value:(_vm.stepNr),callback:function ($$v) {_vm.stepNr=$$v},expression:"stepNr"}},[_c(VStepperHeader,{staticClass:"elevation-0"},[_c(VStepperStep,{attrs:{"complete":_vm.stepNr > 1,"step":"1"}},[_vm._v(" Check out ")]),_c(VDivider),_c(VStepperStep,{attrs:{"complete":_vm.stepNr > 2,"step":"2"}},[_vm._v(" Delivery ")]),_c(VDivider),_c(VStepperStep,{attrs:{"complete":_vm.stepNr > 3,"step":"3"}},[_vm._v(" Invoice ")]),_c(VDivider),_c(VStepperStep,{attrs:{"complete":_vm.stepNr > 4,"step":"4"}},[_vm._v(" Convert ")]),_c(VDivider),_c(VStepperStep,{attrs:{"step":"5"}},[_vm._v(" Done ")])],1),_c(VStepperItems,{staticStyle:{"min-height":"450px","max-height":"600px","overflow-y":"auto"}},[_c(VStepperContent,{attrs:{"step":"1"}},[_c('CartItemTable')],1),_c(VStepperContent,{attrs:{"step":"2"}},[_c('OrderDeliveryForm')],1),_c(VStepperContent,{attrs:{"step":"3"}},[_c('OrderInvoiceForm')],1),_c(VStepperContent,{attrs:{"step":"4"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":"","max-width":600}},[_c(VCardTitle,[_vm._v("Convert shoppingcart to order")]),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.paymentOptions,"disabled":_vm.webUserInfo[0]
													.hasDeferredPayment ===
												true,"item-text":"type","item-value":"code","label":"Payment","hide-details":"","flat":"","single-line":"","outlined":""},model:{value:(_vm.paymentCode),callback:function ($$v) {_vm.paymentCode=$$v},expression:"paymentCode"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Customer reference","outlined":""},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":"Remark","outlined":""},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","color":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.convertCart.apply(null, arguments)}}},[_vm._v("convert to order")])],1)],1)],1),_c(VStepperContent,{attrs:{"step":"5"}},[_c(VBanner,{attrs:{"icon":_vm.errorMsg ? 'mdi-close' : 'mdi-check',"icon-color":_vm.errorMsg ? 'red' : 'green'},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.stepNr = 1}}},[_vm._v(" OK ")])]},proxy:true}])},[(_vm.errorMsg)?_c('span',[_vm._v(" "+_vm._s(this.errorMsg))]):_c('span',[_vm._v(" "+_vm._s(this.result)+" ")])])],1)],1),_c(VToolbar,{attrs:{"flat":""}},[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.showPrev),expression:"showPrev"}],attrs:{"plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.prevStep.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")]),_vm._v("Back ")],1),_c(VSpacer),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.showNext),expression:"showNext"}],attrs:{"plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.nextStep.apply(null, arguments)}}},[_vm._v(" Next "),_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }